@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;700&family=Josefin+Sans:wght@300;400;700&family=Poppins:wght@400;500;700&display=swap');

/* custom scrollbar */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #d1e5ff;
}

::-webkit-scrollbar-thumb {
    background: #ee705e;
    border-radius: 10px;
}

@media (max-width: 480px) {
    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    ::-webkit-scrollbar-thumb {
        width: 3px;
        height: 3px;
    }
}

.wrapper {
    position: relative;
    height: 100%;
    width: 50%;
    height: auto;
    min-height: 100vh;
    padding: 50px 20px;
    padding-top: 100px;
    margin: auto;
}

.wrapper1 {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
}

@media (max-width: 600px) {
    .wrapper {
        width: 100%;
    }
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
}

.background-overlay {
    position: fixed;
    z-index: -2;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: blur(90px);
    -webkit-filter: blur(90px);
}

.background-track-overlay {
    position: fixed;
    z-index: -2;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: blur(20px);
    -webkit-filter: blur(20px);
}

/* .wrapper::before {
    content: "";
    position: fixed;
    z-index: -2;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-image: url(https://cdn.campsite.bio/eyJidWNrZXQiOiJjYW1wc2l0ZS1iaW8tc3RvcmFnZSIsImtleSI6Im1lZGlhL3Byb2ZpbGUtaW1hZ2VzLzQ2MjA2NjE4LTczZGUtNDQ4Yi04NjUxLTQzNTZhZmJkYjFlOS5QTkciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjIwMH19fQ==);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 0.81;
    filter: blur(100px);
    -webkit-filter: blur(100px);
} */

.profile-name {
    font-size: 1.400rem;
    font-family: 'Poppins',
        sans-serif;
    font-weight: 1000;
    color: "#111111";
    margin-bottom: 0;
}

.profile-desc {
    font-weight: 700;
    font-size: 0.925rem;
    text-transform: capitalize;
    text-align: center;
    line-height: 1.3rem;
    color: #808080;
    margin-top: 0.2rem;
}

.footer {
    position: fixed;
    background-color: transparent;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.trademark {
    color: #fff;
}

.logo-link {
    text-decoration: none;
}

.logo {
    color: #ff715b;
    font-weight: 700;
    font-size: 2.5vh;
    background-color: transparent;
}

.Tracklogo {
    color: #ff715b;
    font-weight: 700;
    font-size: 5vh;
}

.gradient-custom {
    /* fallback for old browsers */
    background-image: "linear-gradient(-45deg, #0FD64F, #F8EF42, #EE705E, #20DED3, #788CB6)";
    background-size: 400% 400%;
    animation: gradient 35s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;


    }
}