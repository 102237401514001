.iphone-frame {
  background-color: #fff;
  height: 500px;
  position: relative;
  overflow: hidden;
  width: 260px;
  border: 8px solid #000;
  border-radius: 40px;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 0px #333;
}

.iphone-frame .norch {
  position: absolute;
  top: 0;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  height: 24px;
  width: 100px;
  background-color: #000;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.norch .rounded {
  position: relative;
}

.norch::before {
  content: '';
  position: absolute;
  left: 30px;
  top: 7px;
  height: 4px;
  width: 30px;
  border-radius: 10px;
  border: 0.03em solid rgb(51, 51, 51, 0.479);
  box-shadow: inset 0px 0px 3px 0px rgb(51, 51, 51, 0.712);
}

.norch::after {
  content: '';
  position: absolute;
  right: 20px;
  top: 5px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #333;
}

.norch .rounded::before {
  content: '';
  position: absolute;
  left: -20px;
  /* background-color: #e2b64f; */
  top: 0;
  height: 20px;
  width: 20px;
  z-index: 1;
  border-top-right-radius: 4px;
  box-sizing: border-box;
  box-shadow: 1px -10px 0px 0px #000;
}

.norch .rounded::after {
  content: '';
  position: absolute;
  left: 100px;
  background-color: #e2b64f;
  top: 0;
  height: 20px;
  width: 20px;
  border-top-left-radius: 4px;
  box-sizing: border-box;
  box-shadow: -1px -10px 0px 0px #000;
}

.app-container .bg-img .bg-frame {
  position: relative;
  height: 500px;
  width: 100%;
  background-color: #b7bed0;
}

.app-container .bg-img .bg-frame::before {
  content: '';
  position: absolute;
  right: -350px;
  top: -200px;
  height: 570px;
  width: 570px;
  z-index: 1;
  border-radius: 50%;
  background-image: linear-gradient(#e2b64f 45%, #ac2e2a 90%);
  box-shadow: 40px 60px 40px 0px #091933;
}

.app-container .bg-img .bg-frame:after {
  content: '';
  position: absolute;
  right: -330px;
  top: 200px;
  height: 570px;
  width: 570px;
  border-radius: 50%;
  background-image: linear-gradient(#133164, #142e5aa8);
}

.app-container .bg-img .bg-frame .extra {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 200px;
  z-index: 1;
  transform: rotate(20deg) translateX(20px);
  width: 70px;
  background-color: #ac2e2a;
}

.app-container .bg-img .bg-frame .extra::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 55px;
  height: 20px;
  width: 20px;
  border-top-right-radius: 10px;
  background-color: #091a3502;
  box-shadow: 0px -10px 0px 0px #ac2e2a;
}
.application-menu {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: scale(5);
  visibility: hidden;
}

.applications {
  position: absolute;
  left: 0;
  top: 0;
  transition: .7s;
  height: 100%;
  width: 100%;
  display: inline-flex;
}

.application-menu.active {
  transform: scale(1);
  visibility: visible;
}

/* app-profile-card.css */

/* Add these styles to make the container take 100% width and height */
.mobile-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.mobile-inner-frame {
  background-color: #fff;
  border-radius: 16px;
  width: 80%;
  max-width: 400px;
  height: 90%;
  overflow: auto;
  border: 8px solid #000;
  border-radius: 40px;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 0px #333;
}

.mobile-card {
  padding: 16px;
}

.mobile-container {
  width: 100%;
}

.mobile-inner-frame {
  overflow: hidden;
}

.mobile-container {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mobile-container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
@media (max-width: 767px) {
  .mobile-inner-frame {
    width: 80%; /* Set the same width as the normal frame */
    /* Adjust other styles as needed for responsive layout */
  }
}
@media (max-width: 576px) {
  /* Adjust styles for screens up to 576px wide (e.g., mobile phones) */
  .mobile-inner-frame {
    width: 95%; /* Example: Make it even narrower for smaller screens */
  
  }}