.slgn-sec {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  padding-bottom: 1rem;


}

.slgn {
  font-size: 2rem;
  font-weight: 700;
  color: black;
  text-transform: capitalize;
  margin: 0 0 1rem 0;

}

.anm-prt {
  display: flex;
}

.slgn-wrd {
  overflow: hidden;
  position: relative;
  height: 65px;
  padding-top: 10px;
  margin-top: -10px;
}

.animated-wrd {
  padding: 0 05px;
  margin-bottom: 45px;
  display: block;
}